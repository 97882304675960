import React from 'react';
import Reviews from './pages/reviews/Reviews';
import Review from './pages/review/[reviewName]/index';
import MapBox from 'src/pages/MapBox1';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Reviews />} />
        <Route path='/lp1' element={<MapBox noMap />} />
        <Route path='/lp2' element={<MapBox secondContent />} />
        <Route path='/:slug' element={<Review />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
