import React from 'react';

import Map, { LineLayer, Layer, Marker, Source } from 'react-map-gl';
import type { FeatureCollection } from 'geojson';


import locationMarkerA from 'src/assets/images/A.webp';
import locationMarker from 'src/assets/images/locationMarker.webp';
import locationMarkerB from 'src/assets/images/B.webp';

import 'mapbox-gl/dist/mapbox-gl.css';
import { token } from 'src/utils/contextUtils';
import { useContextData } from 'src/hooks/useContextData';



const MapBox = ({ secondContent }: { secondContent?: boolean }) => {
  const { mapRef, positions, routes, commonLatLng } = useContextData(secondContent);

  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: { type: 'LineString', coordinates: routes },
        properties: null,
      },
    ],
  };

  const layerStyle: LineLayer = {
    id: 'point',
    type: 'line',
    layout: {
      'line-cap': 'round',
    },
    paint: {
      'line-width': 4,
      'line-color': '#4397c4',
    },
  };

  if (!commonLatLng) {
    return <></>;
  }

  return (
    <Map
      ref={mapRef}
      mapboxAccessToken={token}
      initialViewState={{
        longitude: commonLatLng[0],
        latitude: commonLatLng[1],
        zoom: 12,
      }}
      style={{ width: '100%', height: '100%' }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
      {!positions.start ? (
        <Marker longitude={commonLatLng[0]} latitude={commonLatLng[1]} anchor="bottom">
          <img className="h-10 w-6" src={locationMarker} alt="locationMarkerStart" />
        </Marker>
      ) : null}
      {positions.start ? (
        <Marker longitude={positions.start[0]} latitude={positions.start[1]} anchor="bottom">
          <img className="h-10 w-10" src={locationMarkerA} alt="locationMarkerStart" />
        </Marker>
      ) : null}
      {positions.final ? (
        <Marker longitude={positions.final[0]} latitude={positions.final[1]} anchor="bottom">
          <img className="h-10 w-10" src={locationMarkerB} alt="locationMarkerFinal" />
        </Marker>
      ) : null}
      <Source id="my-data" type="geojson" data={geojson}>
        <Layer {...layerStyle} />
      </Source>
    </Map>
  );
};

export default MapBox;
