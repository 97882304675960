import Logo from "src/assets/images/logo.webp";
import { REVIEWS } from "../../constants/common";
import ReviewCard from "../../components/Reviews";
import { Link } from "react-router-dom";
const Review = () => {

  return (
    <>
      <div>
        <div className="bg-[#b1d1e6] p-2">
          <div className="container mx-auto">
            <Link to="/">
              <img
                width={260}
                style={{ display: "block" }}
                src={Logo}
                alt="logo"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="container mx-auto mb-14 ">
        <h1 className="mt-16 text-center font-semibold text-4xl">Reviews</h1>

        <div className="flex gap-7 justify-center flex-wrap xl:flex-nowrap sm:p-0 p-5">
          {REVIEWS.map(({ image, name, slug }, ind) => (

            <ReviewCard key={ind} {...{ image, name, slug }} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Review;
