import React from "react";
import {
  ILocationOptions,
  IPositions,
  IStartAndFinalLocationOptions,
  IMapContext,
  ILocations,
  IRouteInstructions,
} from "src/@types/mapInputs";
import { IRes } from "src/@types/routeData";
import { MapRef } from "react-map-gl";
import { getLocalOpt, getLocalPos, token } from "src/utils/contextUtils";

const MapContext = React.createContext<IMapContext>({} as IMapContext);

export const MapContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const mapRef = React.useRef<MapRef>(null as unknown as MapRef);

  const [positions, setPositions] = React.useState<IPositions>({
    start: null,
    final: null,
  });

  const [routes, setRoutes] = React.useState<number[][]>([]);
  const [routeInstructions, setRouteInstructions] = React.useState<
    IRouteInstructions[]
  >([]);

  const [locations, setLocations] = React.useState<ILocations>({
    start: "",
    final: "",
  });

  const [locationOptions, setLocationOptions] =
    React.useState<ILocationOptions>({ start: [], final: [] });

  const [getDirectionsError, setGetDirectionsError] = React.useState(false);

  const [directionGot, setDirectionGot] = React.useState(false);

  const [commonLatLng, setCommonLatLng] = React.useState<number[] | null>(null);

  const [directions, setDirections] = React.useState<IRes | null>(null);

  const getUserLocation = async () => {
    const response = await fetch(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_PUBLIC_IP_INFO_API}`
    );

    const data = await response.json();
    const [latitude, longitude]: number[] = data.loc.split(",");
    setCommonLatLng([longitude, latitude]);
  };

  React.useEffect(() => {
    getUserLocation();
  }, []);

  const mapFlyTo = (locationOption: IStartAndFinalLocationOptions) => {
    if (locationOption.name === "start")
      mapRef.current?.flyTo({
        center: [locationOption.position[0], locationOption.position[1]],
        duration: 2000,
      });
  };

  const setLocationsForPosition = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocations((pre) => {
      return { ...pre, [event.target.name]: event.target.value };
    });
    const localOpt = await getLocalOpt(event);
    if (localOpt) {
      const localPos = await getLocalPos(localOpt);

      setLocationOptions((pre) => {
        return { ...pre, [event.target.name]: localPos };
      });
    }
  };

  const getDirections = async () => {
    if (!positions.start || !positions.final) {
      console.log("no Directions");
      setGetDirectionsError(true);
      return;
    }
    const startPositions = `${positions.start[0]},${positions.start[1]}`;
    const finalPositions = `${positions.final[0]},${positions.final[1]}`;
    const response = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${startPositions};${finalPositions}?geometries=geojson&steps=true&access_token=${token}`
    );
    const res: IRes = await response.json();
    setDirections(res);

    const routes = res.routes[0].geometry.coordinates;
    routes.unshift(positions.start);
    routes.push(positions.final);
    setRoutes(routes);

    const routeEvents: IRouteInstructions[] = [];
    res.routes[0].legs.forEach((leg) => {
      leg.steps.forEach((step, index) => {
        const instructions = step.maneuver.instruction;
        routeEvents.push({
          id: `${index + 1}${step.maneuver.instruction}`,
          instructions,
        });
      });
    });
    setRouteInstructions(routeEvents);
    setDirectionGot(true);
  };

  const setLocationsAndPositionsState = (
    locationOption: IStartAndFinalLocationOptions
  ): void => {
    setLocations((pre) => {
      return {
        ...pre,
        [locationOption.name]: locationOption.label.fullAddress,
      };
    });
    setPositions((pre) => {
      return {
        ...pre,
        [locationOption.name]: [
          locationOption.position[0],
          locationOption.position[1],
        ],
      };
    });

    if (directionGot) {
      setDirectionGot(false);
    }

    mapFlyTo(locationOption);
    setLocationOptions((pre) => {
      return { ...pre, [locationOption.name]: [] };
    });
  };

  const value = {
    directions,
    commonLatLng,
    mapRef,
    positions,
    setPositions,
    routes,
    routeInstructions,
    getDirections,
    locations,
    setLocations,
    locationOptions,
    setLocationOptions,
    setLocationsForPosition,
    setRoutes,
    setLocationsAndPositionsState,
    getDirectionsError,
    setGetDirectionsError,
    directionGot,
    setDirectionGot,
  };

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export const useMapContext = () => {
  const mapContext = React.useContext(MapContext);
  return mapContext;
};
