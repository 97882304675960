import React from 'react';
import InputDropDown from 'src/components/MapBox1Comp/InputDropDown';
import { ILocations } from 'src/@types/mapInputs';
import { useContextData } from 'src/hooks/useContextData';

const Input = ({ inputFor, placeholder, secondContent }: { inputFor: keyof ILocations; placeholder: string; secondContent?: boolean }) => {
  const { locations, setLocationsForPosition } = useContextData(secondContent);
  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex border-2 p-2 border-[#cccccc] rounded">
        <input
          name={inputFor}
          placeholder={placeholder}
          value={locations ? locations[inputFor]: ''}
          onChange={(event) => {
            if(setLocationsForPosition){
              setLocationsForPosition(event);
            }
          }}
          className="w-full text-black focus:outline-none"
        />
        <div className="flex">
          <div className="w-[2px] mx-3 bg-[#cccccc]" />
          <div className="relative w-6">
            <div className="w-[2px] top-[6px] h-3 left-[12px] z-50 rotate-45 absolute rounded-sm bg-[#cccccc]" />
            <div className="w-[2px] top-[6px] h-3 left-[4px] z-50 -rotate-45 absolute rounded-sm bg-[#cccccc]" />
          </div>
        </div>
      </div>
      <div className="relative w-full">
        <InputDropDown secondContent={secondContent} inputDropDownFor={inputFor} />
      </div>
    </div>
  );
};

export default Input;
