import React from 'react';
import { MapContextProvider } from 'src/Context/MapContext1';
import { SecondMapContextProvider } from 'src/Context/MapContext2';
import Map from 'src/components/MapBox1Comp/Maps/index';

const index = ({
  secondContent,
  noMap = false,
}: {
  secondContent?: boolean;
  noMap?: boolean;
}) => {
  return (
    <div className='bg-white min-h-screen'>
      <MapContextProvider>
        <SecondMapContextProvider>
          <Map noMap={noMap} secondContent={secondContent} />
        </SecondMapContextProvider>
      </MapContextProvider>
    </div>
  );
};

export default index;
