import React from "react";
import { useMapContext } from "src/Context/MapContext1";

const MapInstructions = () => {
  const { routeInstructions } = useMapContext();
  if(!routeInstructions){
    return null
  }
  return (
    <ul className="w-full">
      {routeInstructions.map((routeInstruction, index) => {
        return (
          <li className="py-1" key={routeInstruction.id}>
            {index + 1}, {routeInstruction.instructions}
          </li>
        );
      })}
    </ul>
  );
};

export default MapInstructions;
