/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import MapInstructions from "src/components/MapBox1Comp/MapInstructions";

type ModalProps = {
  isOpen: boolean;
  onClick: () => void;
  heading?: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ isOpen, onClick, heading }) => {
  if (!isOpen) {
    return null;
  }
  const [dots, setDots] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === ".") {
          return "..";
        } else if (prevDots === "..") {
          return "...";
        } else {
          return ".";
        }
      });
    }, 3000);

    setTimeout(() => {
      setLoading(false);
      clearInterval(intervalId);
    }, 8000);
  }, []);

  return (
    <div className="fixed top-0 left-0 flex h-full  w-full items-center justify-center  rounded-lg bg-gray-900 bg-opacity-50">
      <div className="mx-auto flex h-[calc(100vh-5rem)] lg:w-[48rem] md:w-[40rem] w-[30rem] flex-col items-center overflow-auto rounded-lg bg-white px-7 py-5">
        <h1 className="mt-4 text-2xl font-semibold">{heading}</h1>
        <button
          className="mt-5 rounded-lg w-[25rem] bg-green-600 px-10 py-5 text-2xl font-medium text-white"
          onClick={onClick}
        >
          Continue
        </button>
        <div className="w-[22rem] py-2 px-1 text-gray-400 text-sm text-center ">
          <p>
            For better Maps & Directions results, click{" "}
            <b>&quot;Continue&quot;</b> to use the Maps app in OneLaunch.
          </p>
        </div>
        <div className="flex justify-center pb-7 mt-2">
          {loading ? (
            <div className="mt-4 flex">
              <p className="text-md text-gray-400">Loading {dots}</p>
            </div>
          ) : (
            <div className="mt-5 space-y-3 px-4 text-gray-400">
              <MapInstructions />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
