import { useEffect, useState } from 'react';
import Sidebar from 'src/components/MapBox1Comp/Sidebar';
import Modal from 'src/components/MapBox1Comp/Modal';
import { useSearchParams } from 'react-router-dom';
import MapBox from 'src/components/MapBox1Comp/MapBox';
import { useContextData } from 'src/hooks/useContextData';
import mapImg from 'src/assets/images/mapImage.webp';

const Index = ({
  secondContent,
  noMap = false,
}: {
  secondContent?: boolean;
  noMap?: boolean;
}) => {
  const { directions } = useContextData(secondContent);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryParameters] = useSearchParams();

  const sub1 = queryParameters.get('sub1');
  const sub2 = queryParameters.get('sub2');
  const sub3 = queryParameters.get('sub3');
  const Sub3 = queryParameters.get('Sub3');

  useEffect(() => {
    if (directions && !secondContent) {
      setIsModalOpen(true);
    }
  }, [directions, secondContent]);

  // <div className=" flex flex-col sm:flex-row">
  //       <div className="w-full p-6 shadow-xl md:w-[40%] lg:w-[5rem] xl:w-[25%]"></div>
  console.log(noMap);
  return (
    <>
      <div
        className={`${
          noMap ? 'flex flex-col lg:flex-row' : 'flex flex-col sm:flex-row'
        }`}>
        <div
          className={`${
            noMap
              ? 'w-full p-6 shadow-xl md:w-full lg:w-[35%] xl:w-[25%]'
              : 'w-full p-6 shadow-xl md:w-[40%] lg:w-[35%] xl:w-[25%]'
          }`}>
          <Sidebar secondContent={secondContent} />
        </div>
        {!noMap ? (
          <div className='w-full h-screen'>
            <MapBox secondContent={secondContent} />
          </div>
        ) : (
          <div className='w-full lg:h-screen  md:w-full lg:w-[65%] xl:w-[75%]'>
            <img
              src={mapImg}
              alt='map'
              className='md:h-full h-[60vh] w-full md:object-center object-cover'
            />
          </div>
        )}
      </div>

      {!secondContent ? (
        <Modal
          isOpen={isModalOpen}
          onClick={() => {
            window.open(
              `http://track.hudsonlabsclick.info/d5f988ba-0b56-44a6-bf61-19b433cc54c1?Sub1=${sub1}&Sub2=${sub2}&Sub3=${
                sub3 || Sub3
              }&HudsonLP=LP1`
            );
            setIsModalOpen(false);
          }}
          heading='Continue To Maps & Directions'
        />
      ) : null}
    </>
  );
};

export default Index;
