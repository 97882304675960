import { IStartAndFinalLocationOptions } from "src/@types/mapInputs";

export const token = process.env.REACT_APP_PUBLIC_MAPBOX_ACCESS_API;

export const getLocalOpt = async (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  if (!event.target.value) {
    return null;
  }
  let location = event.target.value;

  const types2 =
    "address,country,street,postcode,district,place,neighborhood,prefecture,city,locality,region,block,oaza,chome,poi";
  const sessionToken = "0bf874a5-fd55-4631-88b6-26e6a9409581";
  const API = `https://api.mapbox.com/search/searchbox/v1/suggest?q=${encodeURIComponent(
    location
  )}&limit=10&language=en&country=US&types=${types2}&session_token=${sessionToken}&access_token=${token}`;
  const getLocation = await fetch(API);
  const res = await getLocation.json();
  if (!res.suggestions) {
    return null;
  }
  const data = res.suggestions.filter((value: any) => {
    return value.full_address;
  });
  const dataArray: IStartAndFinalLocationOptions[] = data.map(
    (value: any, index: number) => {
      return {
        id: `${value.name}${value.place_formatted}${index}`,
        name: event.target.name,
        label: {
          name: value.name,
          place: value.place_formatted,
          fullAddress: value.full_address,
        },
        position: [0, 0],
      };
    }
  );
  return dataArray;
};

export const getLocalPos = async (
  localOpt: IStartAndFinalLocationOptions[]
) => {
  const array = Promise.all(
    localOpt.map(async (value) => {
      const API = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        `${value.label.name} ${value.label.place}`
      )}.json?fuzzyMatch=false&access_token=${token}`;
      const response = await fetch(API);
      const res = await response.json();
      value.label.fullAddress = value.label.fullAddress.replace(
        ", United States",
        ""
      );
      value.label.fullAddress = value.label.fullAddress.replace(
        " of america",
        ""
      );
      value.label.fullAddress = value.label.fullAddress.replace(
        " of America",
        ""
      );
      value.position = res.features[0].center;
      return value;
    })
  ).then((res) => {
    return res;
  });
  return array;
};
