import { useSearchParams } from "react-router-dom";
import LocationInputs from "src/components/MapBox1Comp/Sidebar/LocationInputs";
import { useContextData } from "src/hooks/useContextData";

const Sidebar = ({ secondContent }: { secondContent?: boolean }) => {
  const { getDirections } = useContextData(secondContent);

  const [queryParameters] = useSearchParams();

  const sub1 = queryParameters.get("sub1");
  const sub2 = queryParameters.get("sub2");
  const sub3 = queryParameters.get("sub3");
  const Sub3 = queryParameters.get("Sub3");


  return (
    <div className="flex flex-col justify-between">
      <div>
        <h2 className="text-2xl font-medium">Get Driving Directions</h2>
        <LocationInputs secondContent={secondContent} />
        <button
          onClick={() => {
            getDirections();
            if(secondContent){
              const url = `http://track.hudsonlabsclick.info/d5f988ba-0b56-44a6-bf61-19b433cc54c1?Sub1=${sub1}&Sub2=${sub2}&Sub3=${
              sub3 || Sub3
            }&HudsonLP=LP2`;
            window.open(url, "_blank");
            }
          }}
          className="mt-6 w-full rounded-md bg-green-600 py-5 px-8 font-semibold uppercase text-white disabled:cursor-not-allowed disabled:opacity-70"
        >
          Get Directions
        </button>
      </div>
      <div className="fixed bottom-10 text-lg font-bold text-gray-500">
        MapsDirectionsFree
      </div>
    </div>
  );
};

export default Sidebar;
