import { Link } from "react-router-dom";

const ReviewCard = ({ image, name, slug }: any) => {
  return (
    <div className="cursor-pointer mt-12">
      <Link to={`/${slug}`}>
        <div className="w-[16rem] h-[12rem] lg:w-[14rem] lg:h-[10rem]">
          <img
            style={{ width: "100%", height: "100%" }}
            className="max-w-full rounded-lg border-2 max-h-full shadow-2xl hover:scale-105 transition-transform"
            src={image || ""}
            alt={name}
          />
        </div>

        <h2 className="text-center mt-3 font-semibold ">{name}</h2>
      </Link>
    </div>
  );
};

export default ReviewCard;
