import { Link, useParams } from "react-router-dom";
import Logo from "src/assets/images/logo.webp";
import { pageContent } from "src/constants/common";

const Review = () => {
  const {slug} = useParams();
  const content = pageContent?.find((content) => content?.slugs === slug);

  return (
    <div>
      <div className="bg-[#b1d1e6] p-2">
        <div className="container mx-auto">
          <Link to="/">
            <img
              width={260}
              style={{ display: "block" }}
              src={Logo}
              alt="logo"
            />
          </Link>
        </div>
      </div>

      <div className="container mx-auto mt-12 mb-20 p-10">
        <h2 className="mb-5 text-5xl font-bold text-[#0389d3]">
          {content?.name || ""}
        </h2>
        <div className="mt-3 w-5/6">
          <p dangerouslySetInnerHTML={{ __html: content?.content1 }}></p>
        </div>

        <div className="mt-10 w-5/6">
          <p dangerouslySetInnerHTML={{ __html: content?.content2 }}></p>
        </div>

        <div className="mt-5 w-[75%] md:h-[30rem] ">
          <img
            src={content?.img}
            className="max-h-full w-full max-w-full"
            alt={content?.name}
          />
        </div>

        <div className="mt-10 w-5/6">
          <p dangerouslySetInnerHTML={{ __html: content?.content3 }}></p>
        </div>
      </div>
    </div>
  );
};

export default Review;
