import { useMapContext } from 'src/Context/MapContext1';
import { useSecondMapContext } from 'src/Context/MapContext2';
import { IMapContext } from 'src/@types/mapInputs';
import React from 'react';

export const useContextData = (secondContent?: boolean) => {
  const [data, setData] = React.useState<IMapContext>({} as IMapContext);
  
  const firstData = useMapContext();
  const secondData = useSecondMapContext();
  React.useEffect(() => {
    if (!secondContent) {
      setData(firstData);
    } else {
      setData(secondData);
    }
  }, [secondContent, firstData, secondData]);

  return data;
};