import React from "react";
import { ILocationOptions } from "src/@types/mapInputs";
import { useContextData } from "src/hooks/useContextData";

const InputDropDown = ({
  inputDropDownFor,
  secondContent
}: {
  inputDropDownFor: keyof ILocationOptions;
  secondContent?: boolean
}) => {
  const { locationOptions, setLocationsAndPositionsState }= useContextData(secondContent);
  if (!locationOptions || !locationOptions[inputDropDownFor].length ) {
    return null;
  }
  return (
    <ul className="absolute z-[1000] shadow-2xl bg-white w-full">
      { locationOptions[inputDropDownFor].map((locationOption) => {
        return (
          <li
            className="py-1 px-2 text-black hover:bg-slate-200 text-xs font-light"
            key={`${locationOption.id}`}
            onClick={() => {
              setLocationsAndPositionsState(locationOption);
            }}
          >
            {`${locationOption.label.name} ${locationOption.label.place}`}
          </li>
        );
      })}
    </ul>
  );
};

export default InputDropDown;
