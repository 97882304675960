import ImgKiwi from "../assets/images/kiwi.webp";
import ImgAirBnb from "src/assets/images/airbnb.webp";
import ImgHopper from "src/assets/images/hopper.webp";
import ImgPointGuy from "src/assets/images/pointGuy.webp";
import ImgHotelTonight from "src/assets/images/hotelTonight.webp";

export const REVIEW_SLUGS = {
  hopper: "hopper",
  airbnb: "airbnb",
  thePointGuy: "the-point-guy",
  kiwi: "kiwi",
  hotelTonight: "hotel-tonight",
};

export const REVIEWS = [
  {
    name: "Kiwi",
    image: ImgKiwi,
    slug: REVIEW_SLUGS.kiwi,
  },
  {
    name: "AirBnb",
    image: ImgAirBnb,
    slug: REVIEW_SLUGS.airbnb,
  },
  {
    name: "Hopper",
    image: ImgHopper,
    slug: REVIEW_SLUGS.hopper,
  },
  {
    name: "The Point Guy",
    image: ImgPointGuy,
    slug: REVIEW_SLUGS.thePointGuy,
  },
  {
    name: "Weather.com",
    image: ImgHotelTonight,
    slug: REVIEW_SLUGS.hotelTonight,
  },
];

export const pageContent = [
  {
    slugs: REVIEW_SLUGS.kiwi,
    name: "Kiwi",
    content1: `
    Kiwi.com is a travel booking website that offers a unique and innovative approach to travel planning. The website allows users to search for flights, trains, and buses, and offers multi-city itineraries, making it easy to plan complex trips with multiple stops.
    <br/>
    <br/>
    One of the standout features of Kiwi.com is its "Nomad" feature, which allows users to plan and book multi-city trips with ease. This feature is particularly useful for travelers who want to visit multiple destinations on a single trip, as it offers a simple and efficient way to plan and book complex itineraries.`,
    content2: `Kiwi.com also offers a "Virtual Interlining" feature, which allows users to combine flights from different airlines and book them as a single itinerary. This feature can often result in significant cost savings compared to booking each flight separately.`,
    content3: `Another great feature of Kiwi.com is its user-friendly interface. The website is well-designed and easy to navigate, making it simple for users to find the information they need. Additionally, the website offers a range of filters and sorting options that allow users to customize their search results based on various criteria such as price, duration, and airline.`,
    img: ImgKiwi,
  },
  {
    slugs: REVIEW_SLUGS.airbnb,
    name: "AirBnb",
    content1: `Airbnb.com is a popular online platform that connects travelers with local hosts who offer unique accommodations such as apartments, houses, and rooms. With over 7 million listings in more than 220 countries, Airbnb is the go-to destination for travelers who want to experience the world in a more authentic and affordable way.
      <br/>
      <br/> 
    One of the standout features of Airbnb.com is the variety of accommodation options available. Users can choose from a wide range of properties, from budget-friendly rooms to luxurious villas, making it suitable for all types of travelers. The website also allows users to filter their search results based on various criteria such as price, location, and amenities.
    `,
    content2: `Airbnb also has a robust review system that allows users to read and leave feedback on their experiences. This helps to ensure that users have a positive and safe experience with their host and accommodation. Additionally, Airbnb offers a secure payment system that protects both the host and the guest from fraud or scams.`,
    content3: `Another great feature of Airbnb.com is the "Experiences" section, which offers users unique activities and tours hosted by locals. This allows travelers to get an authentic experience of the local culture and community, which is not always possible with traditional tourist activities.`,
    img: ImgAirBnb,
  },

  {
    slugs: REVIEW_SLUGS.hopper,
    name: "Hopper",
    content1: `Hopper.com is a fantastic travel booking website that offers users the ability to search for and book flights, hotels, and rental cars. What sets Hopper.com apart from other travel booking websites is its ability to predict future prices for flights and hotels, allowing users to make more informed booking decisions.
     <br/>
     <br/>
    One of the standout features of Hopper.com is its "Watch a Trip" feature, which allows users to monitor the price of a particular flight and receive notifications when the price drops. This is particularly useful for those who are flexible with their travel plans and want to get the best possible price.
    `,
    content2: `Hopper.com also offers a user-friendly interface that makes it easy to navigate and find the information you need. The website is well-designed and responsive, making it accessible from any device. The search function is also quite powerful, allowing users to filter their search results by various criteria such as price, duration, and airline.`,
    content3: `Another feature that I found to be particularly useful is the ability to book flights and hotels together as part of a package. This can often result in significant savings compared to booking each component separately.`,
    img: ImgHopper,
  },

  {
    slugs: REVIEW_SLUGS.thePointGuy,
    name: "The Point Guy",
    content1: `ThePointsGuy.com is a website that provides valuable information and insights on travel, credit cards, and loyalty programs. The website is dedicated to helping travelers maximize their points and miles to save money and get the most out of their travels.
    <br/>
    <br/>
    One of the standout features of ThePointsGuy.com is its comprehensive and detailed reviews of credit cards and loyalty programs. The website provides users with in-depth analysis of various credit cards and loyalty programs, including their benefits, rewards, and fees. This information is extremely valuable for users who want to make informed decisions about which credit card or loyalty program to sign up for.
    `,
    content2: `ThePointsGuy.com also offers a wealth of information on travel-related topics such as flight and hotel reviews, travel news, and tips for saving money on travel. The website's team of writers and contributors are knowledgeable and experienced in the travel industry, which makes the content on the website both informative and trustworthy.`,
    content3: `Another great feature of ThePointsGuy.com is its user-friendly interface. The website is well-organized and easy to navigate, making it simple for users to find the information they need. Additionally, the website offers helpful tools such as a credit card comparison tool and a miles calculator, which allow users to compare different credit cards and loyalty programs and calculate the value of their miles and points.`,
    img: ImgPointGuy,
  },

  {
    slugs: REVIEW_SLUGS.hotelTonight,
    name: "Weather.com",
    content1: ` 
    HotelTonight.com is a popular travel booking website that specializes in last-minute hotel bookings. The website offers a simple and easy-to-use platform that allows users to quickly search for and book discounted hotel rooms in a variety of destinations around the world.
    <br/>
    <br/>
    One of the standout features of HotelTonight.com is its focus on last-minute bookings. The website offers discounted rates on hotel rooms that have not been booked, making it a great option for travelers who need to book a hotel at the last minute. The website also offers a range of filters and sorting options, making it easy for users to find the perfect hotel room that meets their needs.
    `,

    content2: `Another great feature of HotelTonight.com is its user-friendly interface. The website is well-designed and easy to navigate, making it simple for users to find the information they need. The website also offers a mobile app, which allows users to search for and book hotel rooms on the go.`,
    content3: `HotelTonight.com also offers a rewards program called "HT Perks," which allows users to earn discounts and free hotel nights. This is a great incentive for frequent travelers who want to save money on their hotel bookings`,
    img: ImgHotelTonight,
  },
];
