import ImgA from "src/assets/images/A-green.webp";
import ImgB from "src/assets/images/B.webp";
import Input from "src/components/MapBox1Comp/Input";

const LocationInputs = ({ secondContent }: { secondContent?: boolean }) => {
  return (
    <div>
      <div className="mt-5 flex w-full items-center gap-3">
        <img
          src={ImgA}
          width={30}
          height={30}
          style={{ color: "green" }}
          alt="placeholder"
        />
        <div className="w-full">
          <Input inputFor="start" placeholder="Enter Your Location" secondContent={secondContent}/>
        </div>
      </div>

      <div className="mt-5 flex w-full items-center gap-3">
        <img src={ImgB} width={30} height={30} alt="placeholder" />
        <div className="w-full">
          <Input inputFor="final" placeholder="Enter Your Location" secondContent={secondContent}/>
        </div>
      </div>
    </div>
  );
};

export default LocationInputs;
